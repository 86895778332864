import { useState } from 'react';
import styles from './Dashboard.module.css';
import { RiDeleteBin6Line } from "react-icons/ri";
import { SlCallIn } from "react-icons/sl";
import { LuShieldQuestion } from "react-icons/lu";
import { PiCursorClickLight } from "react-icons/pi";
import { FaRegThumbsUp,FaRegThumbsDown,FaExclamation} from "react-icons/fa";
import { PieChart,pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { CiChat1 } from "react-icons/ci";

export function generateRandomId() {
  const min = 1000000
  const max = 9999999
  const result=Math.floor(Math.random() * (max - min + 1)) + min
  const date=new Date()
  date.setMinutes(1)
  return date.toISOString()+result.toString()
}

const chat1={
  enviorment:"production",
  chatId:generateRandomId(),
  language:"fi",
  companyId:"flow-festival",
  isExample:false,
  feedback:{userRating:5,userComment:"great"},
  read:false,
  chats:[
    {
      role:"assistant",
      content:"Hei, miten voin auttaa",
      suggestions:[]
    },
    {
      role:"user",
      content:"Missä on flow-festival?",
      time:"19:01",
    },
    {
      role:"assistant",
      content:"Flow festival järjesteään Helsingissä suvissaristossa.",
      suggestions:[{type:"text-suggestion",content:"Miten festivaaleille kannattaa saapua?"},{type:"text-suggestion",content:"Mistä saan lipun?",clicked:true}],
      links:[{type:"link",content:"Löydät lisää sivuiltamme",url:"https://flowfestival.com"}],
      sources:[],
      context:[],
      isSpam:false,
      LLMUsage:[],
      connect:false,
      question:false,
      isPositive:true
    },
    {
      role:"user",
      content:"Mistä saan lipun?",
      time:"19:40",
    },
    {
      role:"assistant",
      content:"Saat lipun sivuiltamme, Ticketmasterista tai muualta. Minkä päivän lippua olet ostamassa?",
      suggestions:[],
      links:[{type:"link",content:"Osta lippu täältä",url:"https://flowfestival.com/tickets"}],
      sources:[],
      context:[],
      isSpam:false,
      LLMUsage:[],
      connect:false,
      question:true
    },
    {
      role:"user",
      content:"sdfs f sdf sfg  ofg",
      time:"19:41",
    },
    {
      role:"assistant",
      content:"Anteeksi, en osaa vastata tähän kysymykseen.",
      suggestions:[],
      links:[],
      sources:[],
      context:[],
      isSpam:true,
      LLMUsage:[],
      connect:false,
      question:false,
      isPositive:false
    },
  ]
  }
  const chat2={
    enviorment:"production",
    chatId:generateRandomId(),
    language:"fi",
    companyId:"flow-festival",
    isExample:false,
    read:false,
    chats:[
      {
        role:"assistant",
        content:"Hei, miten voin auttaa",
        suggestions:[]
      },
      {
        role:"user",
        content:"Kuka on perjantain pääesiintyjä?",
        time:"19:12",
      },
      {
        role:"assistant",
        content:"Perjantain pääesiintyjä on Erika.",
        suggestions:[],
        links:[],
        sources:[],
        context:[],
        isSpam:false,
        LLMUsage:[],
        connect:false,
        question:false
      },
      {
        role:"user",
        content:"Voitko yhdistää minut asiakaspalvelijaan",
        time:"19:15",
      },
      {
        role:"assistant",
        content:"Kyllä, voit yhdistää itsesi asiakaspalvelijaan.",
        suggestions:[{type:"connect-button",content:"Yhdistä minut",clicked:true}],
        links:[],
        sources:[],
        context:[],
        isSpam:false,
        LLMUsage:[],
        connect:true,
        question:false
      },
      {
        role:"user",
        content:"Yhdistä minut",
        time:"19:15",
      },
      {
        role:"assistant",
        content:"Selvä, voit täyttää lomakkeen.\nKiitos, olemme sinuun yhteydessä pian!",
        suggestions:[],
        links:[],
        sources:[],
        context:[],
        isSpam:false,
        LLMUsage:[],
        connect:false,
        question:false,
        form:{name:{value:"lauri",type:"input"},phone:{value:"123234234",type:"input"},email:{value:"lauri@gmail.com",type:"input"},message:{value:"Miten saan yhteyttä markkinointi johtajaan?",type:"textarea"}}
      },
    ]
    }
  const chatsTemplate=[chat1,chat2]



function formatDate(date){
  const curDate=new Date()
  const curDay=curDate.getDate()
  const dateObj=new Date(date.slice(0,-7))
  const day=dateObj.getDate()

  const hours=dateObj.getHours()
  const minutes=dateObj.getMinutes()
  const time=`${hours}:${minutes>9?minutes:"0"+minutes}`
  console.log(curDay,day)
  if(curDay===day){
    return `Tänään ${time}`
  }

  const month=dateObj.toLocaleDateString('en-US', { month: 'short' })
  return `${day} ${month} ${time}`
}
const handleChatReadClick=()=>{

}

const chatList=[chat1,chat2]






function DashBoard({dbChats}) {
  console.log(dbChats)
  const [selectedChat,setSelectedChat]=useState(null)
  const [chats,setChats]=useState(chatList.concat(dbChats))
  const selectedChatForm=selectedChat?.chats.find(n=>n.form)
  
  const totalInteractions=selectedChat?.chats.filter(n=>n.role==="user" && !n.suggestion).length
  const spamCount=selectedChat?.chats.filter(n=>n.isSpam).length
  const failedCount=selectedChat?.chats.filter(n=>n.failed).length


  const handleChatSelect=(chat)=>{
    if(!chat.read){
      handleChatReadClick(chat)
      chat.read=true
    }
    setSelectedChat(chat)
    setChats([...chats])
  }

  const handleFormResolved=()=>{
    selectedChat.chats.find(n=>n.form).resolved=true
    setChats([...chats])
  }


  return (
    <div className={styles.container}>
      <div className={styles.chatDashBoard}>
        <div className={styles.chatList}>
            {chats.map((chat,i)=>{
              const form=chat.chats.find(n=>n.form)
              console.log(form)
              return(
                <div key={i} onClick={()=>handleChatSelect(chat)} className={styles.clickableChatItem} style={selectedChat===chat?{backgroundColor:"white",color:"var(--primary-color)"}:{}}>
                  <span className={styles.chatNotReadDotHolder}>{!chat.read &&<div className={styles.chatNotReadDot}/>}</span>
                  <span>{formatDate(chat.chatId)}</span>
                  {(form && !form.resolved) && <span className={styles.formNotResolved}><FaExclamation/></span>}
                  <span className={styles.chatCount}><CiChat1/><span>{chat.chats.filter(k=>k.role==="user").length}</span></span>
                </div>
              )
            })}
        </div>

        <div className={styles.chatAreaHolder}>
          <div className={styles.chatArea}>
          {
              selectedChat && selectedChat.chats.map((chat,i)=>{
                const splittedChat = chat.content.split("\n")
                return(
                  <div key={i} className={styles.chatHolder}>
                    {chat.suggestions?.find(n=>n.type==="connect-button")?.clicked && <div className={styles.center}><SlCallIn/>Asiakas halusi ottaa yhteyttä</div>}
                    {chat.isSpam && <div className={styles.center}><RiDeleteBin6Line/>Viesti tunnistettiin roskapostiksi</div>}
                    <div className={`${styles.chat} ${styles[chat.role]}`}>{splittedChat[0]}</div>
                    {chat.form && 
                    <div className={styles.form}>
                      {Object.keys(chat.form).map((n,formIndex)=>{
                        return <div key={formIndex} className={styles.formItem}>
                          <div>{n}:</div>
                          {
                            chat.form[n].type==="input"&&<input value={chat.form[n].value} readOnly />
                          }
                          {
                            chat.form[n].type==="textarea"&&<textarea value={chat.form[n].value} readOnly />
                          }
                          </div>
                      })}
                    </div>
                    }
                    {splittedChat.length>1&& splittedChat.slice(1).map((n,splitChatIndex)=>{
                      return <div key={splitChatIndex} className={`${styles.chat} ${styles[chat.role]}`}>{n}</div>
                    })}
                    {chat.isPositive===true && <div className={styles.center}><FaRegThumbsUp/>Asiakas tykkäsi vastauksesta</div>}
                    {chat.isPositive===false && <div className={styles.center}><FaRegThumbsDown/>Asiakas ei tykännyt vastauksesta</div>}

                    {chat.question && <div className={styles.center}><LuShieldQuestion/>Assistentti kysyi lisäkysymyksen</div>}
                    {chat.suggestions?.length > 0 && chat.suggestions.map((suggestion, i2) => (
                      <div key={i2} className={styles.suggestionHolder}>
                        <div style={suggestion.clicked?{backgroundColor:"var(--primary-color)",color:"#fff"}:{}} className={`${styles.suggestion} ${styles.center}`}>
                          {suggestion.content}
                        </div>
                        {suggestion.clicked && <div className={styles.center}><PiCursorClickLight/>Asiakas painoi nappia</div>}
                      </div>
                    ))}

                    {chat.links?.length > 0 && chat.links.map((link, i3) => (
                      <div className={styles.linkHolder} key={i3}>
                        <div className={`${styles.linkButton}`}>{link.content}</div>
                        <a style={{margin:"0 auto",display:"flex"}} href={link.url} target='_blank'>{link.url}</a>
                        {!link.clicked && <div className={styles.center}><PiCursorClickLight/>Asiakas painoi Linkkiä</div>}
                      </div>
                    ))}

                    {chat.connect&& !chat.suggestions?.find(n=>n.type==="connect-button")?.clicked && <div className={styles.center}><SlCallIn/>Asiakkaalle tarjottiin yhteydenottoa</div>}
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className={styles.currentChatInfoHolder}>
      {
        selectedChat &&
        <div className={styles.currentChatInfo}>
            <div>{formatDate(selectedChat.chatId)}</div>
            <div>Kysymysten määrä: {selectedChat.chats.filter(n=>n.role==="user").length}</div>
            <div className={styles.pieChart}>
              <PieChart
              colors={["black","var(--red)","var(--green)"]}
                series={[
                  {
                    data: [
                      { id: 0, value: spamCount, label: 'Spam' },
                      { id: 1, value: failedCount, label: 'Failed' },
                      { id: 2, value: totalInteractions-failedCount-spamCount, label: 'Success' },
                    ],
                    innerRadius: 30,
                    outerRadius: 53,
                    paddingAngle: 3,
                    arcLabel: (item) => `${item.value}`,
                    arcLabelMinAngle: 45,
                    sx: {
                      [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: 'bold',
                      },
                    },
                  },
                ]}

                width={280}
                height={160}
              />
            </div>
            <div>Palautteet:</div>
            <div className={styles.userFeedBack}>
                <div style={{margin:"0"}}>Arvosana: {selectedChat.feedback?.userRating||"-"}/5</div>
                <div style={{display:"flex",alignItems:"center",gap:"10px",height:"20px",margin:"0"}}>
                    <div>
                      <FaRegThumbsDown/>
                      {selectedChat.chats.filter(n=>n.isPositive===false).length}
                    </div>
                    <div>
                      <FaRegThumbsUp/>
                      {selectedChat.chats.filter(n=>n.isPositive).length}
                    </div>
                  </div>
          </div>
          <div style={{marginTop:"10px"}}>Kommentti:</div>
          {
            selectedChat.feedback?.userComment
            ?<textarea value={selectedChat.feedback.userComment} style={{marginBottom:"10px",resize:"none"}} readOnly/>
            :<div style={{marginBottom:"10px"}}>-</div>
          }
          <div style={{display:"flex",alignItems:"center",gap:"4px"}}><span>Käyttäjän painamat napit</span> <span className={styles.clickAmount}>{selectedChat.chats.map(n=>n.suggestions).flat().filter(n=>n?.clicked).length+selectedChat.chats.map(n=>n.links).flat().filter(n=>n?.clicked).length}</span></div>
{/*           <div style={{display:"flex",gap:"4px",alignItems:"center",flexDirection:"column",backgroundColor:"white",color:"black",width:"90%",maxHeight:"300px",overflowY:"scroll"}}>
            {
              selectedChat.chats.map(n=>n.suggestions).flat().filter(n=>n?.clicked).map((n,i)=>{
                return <div key={i} style={{borderBottom:"1px solid black",width:"100%",textAlign:"center",padding:"3px"}}>{n.content}</div>
              })
            }
            {
              selectedChat.chats.map(n=>n.links).flat().filter(n=>n?.clicked).map((n,i)=>{
                return <div key={i} style={{borderBottom:"1px solid black",width:"100%",textAlign:"center",padding:"3px"}}><span>{n.content}</span><br/> <a href={n.url} target="_blank">{n.url}</a></div>
              })
            }
          </div> */}

          <div style={{marginTop:"10px"}}>Yhteydenotto lomake:</div>     
          {selectedChatForm
              ?<div className={styles.form} style={{width:"95%",border:`1px solid ${selectedChatForm.resolved?"white":"var(--red)"}`}}>
              {
              Object.keys(selectedChatForm.form).map((n,formIndex)=>{
                return <div key={formIndex} className={styles.formItem}>
                  <div>{n}:</div>
                  {
                    selectedChatForm.form[n].type==="input"&&<input value={selectedChatForm.form[n].value} readOnly />
                  }
                  {
                    selectedChatForm.form[n].type==="textarea"&&<textarea value={selectedChatForm.form[n].value} readOnly />
                  }
                  </div>
              })}
              <button onClick={()=>handleFormResolved()}>Merkkaa luetuksi</button>
            </div>
              :<div>-</div>
            }

      </div>
      }
      </div>

      <div className={styles.allChatInfo}>
            <div>conversation amount,user message amount,percentages</div>
            <div>pie chart,percentages</div>
            <div>stats,questions,suggestions,links,thumbs,clicks,percentages</div>
            <div>context,sources</div>
            <div>feedback averages,contact requests</div>
      </div>

    </div>
  );
}

export default DashBoard;
